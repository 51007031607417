import NavBar from '../components/NavBar'
import Testimonial from '../components/Testimonial'
import ContactMeFooter from '../sections/ContactMeFooter'

export default function TestimonialsPage() {
    return (
        <div>
            <NavBar />
            <div className='bg-white'>
                <div className='mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8'>
                    <div className='items-end justify-between sm:flex'>
                        <div className='max-w-xl'>
                            <h2 className="text-base font-semibold leading-7 text-sky-600">
                                Testimonials
                            </h2>
                            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                Read trusted reviews from people I have worked with
                            </h1>
                        </div>
                    </div>
                    <div className="mt-8 grid grid-cols-1 gap-4 sm:grid-cols-1 lg:grid-cols-2">
                        <Testimonial 
                            description='I have known Noelle Zhang several years.
                            I recently decided to move to Alaska to be near my grand-children and daughter. Needing to sell my home, I asked Noelle to help me with the selling.
                            Noelle not only helped with the selling of my home, but also helped me sell my car and golf cart.
                            She went above and beyond the normal duties of an agent and helped me with painting and staging of my home. She sold my home in less time than most of the sales in the neighborhood. It took her about one month to find a qualified buyer. She set me up with a trust fund of the sale and arranged all the paperwork, making this an easy and quick process. 
                            I really highly recommend her!
                            '
                            name='David Kiser'
                        />
                        <Testimonial 
                            description='A few years ago, we found our dream home near our daughter and grand kids, which Noelle Zhang was managing. We really wanted to own that property, but we could not do it through the traditional way. Noelle helped us to rent it for a few years and build up our down payment. Now we are in escrow to become the new owner.
                            It has been a pleasant experience to work with Noelle. She is trustworthy, very fair and responding to our request fast. If you would like additional information about this, you can telephone me at telephone number.
                            '
                            name='JoAnn & James Lahrs'
                        />
                        <Testimonial
                            description='I have known Noelle Zhang since July 2013 after I moved back to San Diego from Cheektowaga, New York.
                            I owned a property in Cheektowaga, New York, which I bought a few years back. I did not want to rent it to a stranger and manage tenants, so I rented it to my nephew and I had to subsidize it about $700 a month for him to live there. I would also have to be out of the pocket if I sell it through a realtor.
                            Noelle offered to help. She found an older couple who desperately wanted the property, but they did not have the money and credit to buy it through the traditional way. Noelle set up a “Rent-To-Own” program for them, so they could eventually own the property. Noelle manages the property, pays my mortgage since 2013. Now the couple is in escrow to become the new owner of the property. Meanwhile, I don’t have any out-of-pocket expenses anymore and no worries for the property.  Thanks for Noelle’s hard and continuous work to solve the problems for both sides.
                            I highly recommend her!
                            '
                            name='Michael J Puff'
                        />
                        <Testimonial
                            description='About two years ago I was trying to sell my mobile home in Desert Hot Springs, California. During this period, I met Ms. Noelle Zhang.  Ms. Zhang helped me create an "Owners Financing Plan" to open up my buyer’s pool.  Because of that we found a qualified buyer who had the required down payment, and the background references to make the purchase.
                            Ms. Zhang created all necessary paperwork for the sale, including the "Promissory Note", with the "Default Clause" and the "Application Form" for my own protection against a default of sale.
                            It was a pleasure working with Ms. Zhang, she is very professional and responsive.
                            '
                            name='Roy Cook'
                        />
                        <Testimonial 
                            description='My husband and I first met Ms. Zhang in November 2009 when we looked into the purchase of our current home on Treeside Lane. Ms. Zhang was representing the owner and had worked diligently over the past months to market the home and find the right buyer.
    
                            Because of Ms. Zhang’s constant attention, communication and support for both parties, a very successful contact was negotiated and executed for the purchase of the Treeside Lane property!
                            Ms. Zhang is a fair, considerate and compassionate professional and individual. I highly recommend her as a real estate representative to work with families through what can be a difficult time when a beloved family home is going on the market. 
                            Please feel free to contact me if you would like to discuss our experience.
                            '
                            name='Betty Ann Field'
                        />
                        <Testimonial
                            description='I have known Noelle Zhang since 2006, through our membership in Toastmasters International.  I recognize and honor the incredible strength, focus, and warm heart that Noelle embodies and shares so willingly with others. She beautifully demonstrates her ability to recognize and do whatever needs to be done to accomplish any goal and her enthusiasm to be of service to others.  She is highly trustworthy and capable of achieving whatever she chooses to pursue.  Noelle is a joy and an inspiration to all of us at Northcoast Toastmasters and I am proud to call her my friend.'
                            name='Greg Betts'
                        />
                        <Testimonial
                            description='I have had a very positive experience with Noelle Zhang in terms of evaluating the worth of my property. Noelle spent time showing me how to sell my home “as is” at a fair price and on the date of my choice. I appreciate her no-pressure and friendly service. She is knowledgeable about the local real estate market.'
                            name='Mark Abel'
                        />
                        <Testimonial
                            description=' I have known Noelle Zhang for over four years.  In my relationship with her I have found Noelle to be a trustworthy and reliable friend.  She is a diligent and tireless worker who focuses on getting things done.  I am more than happy to recommend Noelle to anyone who does business with her. '
                            name='Alan R Olson'
                        />
                    </div>
                </div>
            </div>
            <ContactMeFooter />
        </div>
    )
}