import NavBar from '../components/NavBar'

export default function ThankYouPage() {
    return (
        <div>
            <NavBar />
            <div className='px-6 py-24 sm:px-6 sm:py-32 lg:px-8'>
                <div className='mx-auto max-w-2xl text-center'>
                    <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
                        Thank you!
                    </h2>
                    <p className='mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600'>
                        Looking forward to working with you. 
                    </p>
                    <div className='mt-5'>
                        <a 
                            href="/" 
                            className="text-sm font-semibold leading-6 text-gray-900"
                        >
                            Home <span aria-hidden="true">→</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}