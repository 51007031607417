import Testimonial from '../components/Testimonial'

export default function TestimonialsSection() {
    return (
        <div className='bg-white'>
            <div className='mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8'>
                <div className='items-end justify-between sm:flex'>
                    <div className='max-w-xl'>
                        <h2 className="text-base font-semibold leading-7 text-sky-600">
                            Testimonials
                        </h2>
                        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            Read trusted reviews from people I have worked with
                        </h1>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Looking to hear from satisfied clients? Check out our testimonials page to see what others have to say about working with me and my commitment to providing exceptional real estate services. 
                        </p>
                    </div>
                    <a
                        href="/testimonials"
                        class="mt-8 inline-flex shrink-0 items-center gap-2 rounded-full border border-sky-600 px-5 py-3 font-medium text-sky-600 hover:bg-sky-600 hover:text-white sm:mt-0 lg:mt-8"
                    >
                        Read all reviews

                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M14 5l7 7m0 0l-7 7m7-7H3"
                        />
                        </svg>
                    </a>
                </div>
                <div className="mt-8 grid grid-cols-1 gap-4 sm:grid-cols-1 lg:grid-cols-3">
                    <Testimonial 
                        description='She went above and beyond the normal duties of an agent and helped me with painting and staging of my home. She sold my home in less time than most of the sales in the neighborhood. It took her about one month to find a qualified buyer. She set me up with a trust fund of the sale and arranged all the paperwork, making this an easy and quick process. 
                        I really highly recommend her!'
                        name='David Kiser'
                    />
                    <Testimonial 
                        description='We really wanted to own that property, but we could not do it through the traditional way. Noelle helped us to rent it for a few years and build up our down payment. Now we are in escrow to become the new owner.
                        It has been a pleasant experience to work with Noelle. She is trustworthy, very fair and responding to our request fast.'
                        name='JoAnn & James Lahrs'
                    />
                    <Testimonial 
                        description='Ms. Zhang helped me create an "Owners Financing Plan" to open up my buyer’s pool....
                        Ms. Zhang created all necessary paperwork for the sale, including the "Promissory Note", with the "Default Clause" and the "Application Form" for my own protection against a default of sale.
                        It was a pleasure working with Ms. Zhang, she is very professional and responsive.'
                        name='Roy Cook'
                    />
                </div>
            </div>
        </div>
    )
}