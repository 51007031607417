import NavBar from '../components/NavBar'
import BuyAHomeInfo  from '../sections/BuyAHomeInfo'
import ContactMeForm from '../sections/ContactMeFooter'

export default function BuyAHomePage() {
    return (
        <div>
            <NavBar activePage="Buy a Home" />
            <BuyAHomeInfo />
            <ContactMeForm />
        </div>
    )
}