import { StarIcon } from "@heroicons/react/20/solid"

export default function Testimonial(props) {
    return (
        <blockquote className='flex h-full flex-col justify-between bg-gray-100 shadow-xl rounded-2xl  p-12 hover:shadow-2xl'>
        <div>
            <div className='flex gap-0.5'>
                <StarIcon className='text-sky-500 h-6 w-6'/>
                <StarIcon className='text-sky-500 h-6 w-6'/>
                <StarIcon className='text-sky-500 h-6 w-6'/>
                <StarIcon className='text-sky-500 h-6 w-6'/>
                <StarIcon className='text-sky-500 h-6 w-6'/>
            </div>
            <div className='mt-4'>
                <p classsName='text-gray-500'>
                    {props.description}
                </p>
            </div>
        </div>

        <footer className='mt-8 text-gray-500'>
            {props.name}
        </footer>
    </blockquote>
    )
}