import { XCircleIcon } from '@heroicons/react/20/solid'

export default function XCrossListItem(props) {
    return(
        <li className="flex gap-x-3">
        <XCircleIcon className="mt-1 h-5 w-5 flex-none text-sky-600" aria-hidden="true" />
        <span>
            <strong className="text-gray-900">{props.content}</strong>
        </span>
        </li>
    )
}