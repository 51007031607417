export default function Hero() {
    return (
        <div className='relative isolate overflow-hidden pt-14'>
            <img
                src='https://images.pexels.com/photos/2157685/pexels-photo-2157685.jpeg?auto=format&fit=crop&w=2830&q=80&sat=-20&exp=-30&blend-mode=multiply'
                className="absolute inset-0 -z-10 h-full w-full object-cover" alt="The beautiful San Diego Harbor at dusk. Trees line a rocky embankment which meets the water, and boats sit moored to docks in front of the downtown cityscape, which is just beginning to twinkle."
            />
            <div
                className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                aria-hidden="true"
            >
                <div
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-sky-950 to-sky-800 opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                    style={{
                    clipPath:
                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>
            <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
                <div className="text-center">
                    <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Your Trusted San Diego Real Estate Professional</h2>
                    <p className="mt-6 text-lg leading-8 text-white">
                        With over 30 years of roots in San Diego and a background in biotech research, I bring a unique perspective to the real estate industry.
                    </p>
                    <p className="mt-6 text-lg leading-8 text-white">
                    My commitment is to provide the best possible services to help you achieve your real estate goals, whether it's buying, selling, or managing rental properties.
                    </p>
                    <a
                        type="button"
                        className="mt-6 rounded-full bg-sky-600 px-10 py-3 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                        href='/contactme'
                    >
                        Contact Me
                    </a>
                </div>
            </div>
        </div>
    )
  }
  