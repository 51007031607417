import NavBar from '../components/NavBar';
import SellYourHomeInfo from '../sections/SellYourHomeInfo';
import ContactMeForm from '../sections/ContactMeFooter';

export default function SellYourHomePage() {
    return (
        <div>
            <NavBar activePage="Sell Your Home" />
            <SellYourHomeInfo />
            <ContactMeForm />
        </div>
    )
}