import XCrossListItem from '../components/XCrossListItem'
import CheckListItem from '../components/CheckListItem'

export default function SellYourHomeInfo() {
    return (
        <div className="bg-white px-6 py-32 lg:px-8">
            <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Sell Your Home</h1>
                <p className="mt-6 text-xl leading-8">
                There are a few different routes you can choose to sell your home.
                </p>
                <div className="mt-10 max-w-2xl">

                <h2 className='text-2xl font-bold text-gray-800'>
                    Sell with an agent, like me.
                </h2>
                <p className='mt-2 text-lg'>
                    I am a CA licensed agent (CA DRE 02033856). I can help you by listing your property on MLS. I recently helped a client sell his rental property for above the listing price. 
                </p>
                <p className='mt-3'>
                    Selling a house is typically an expensive and complicated process. That's why real estate agents often make thousands, sometimes tens of thousands, of dollars on a single sale. In addition to being costly, to sell your house at the price you want, you must fix up the property and tolerate dozens of strangers, some potentially unsettling, walking through your home and rummaging through your drawers and closets. When mortgage interest rates rise, as they have recently, many buyers may not qualify for loans, causing your property to remain on the market for a longer time or forcing you to lower your asking price.
                </p>

                <h2 className='mt-20 text-2xl font-bold text-gray-800'>
                    Sell your property yourself - without an agent.
                </h2>
                <p className='mt-3 text-lg'>
                    However, statistics show that 90% of "for sale by owner" properties eventually have to hire an agent to sell them. Considering the time and frustration involved, do you want to take that route?
                </p>

                <h2 className='mt-20 text-2xl font-bold text-gray-800'>
                    Sell your property to me
                </h2>
                <p className='mt-3 mb-5 text-lg'>
                   I am associated with a group of private investors and contractors. 
                </p>
                <h2 className='mt-6 text-lg text-bold text-gray-900'>
                   This can help you avoid:
                </h2>
                <ul className="mt-3 max-w-xl space-y-2 text-gray-600">
                    <XCrossListItem content='Putting your house on the market entirely...' />
                    <XCrossListItem content='Coming out of pocket if you have little or no equity...' />
                    <XCrossListItem content='Relying on an agent to perform or keep their promises...' />
                    <XCrossListItem content='Doing fix-up work to please a picky buyer...' />
                    <XCrossListItem content='Becoming an unwanted landlord...' />
                    <XCrossListItem content="Counting on a bank to approve your buyer's loan..." />
                    <XCrossListItem content='Paying prepayment penalities...' />
                    <XCrossListItem content='Having the cash you need NOW that is currently tied up in your house...' />
                    <XCrossListItem content='Struggling with the uncertainty of when it will sell...' />
                    <XCrossListItem content='Making house payments you can no longer afford...' />
                    <XCrossListItem content='Foreclosure or bankruptcy...' />
                </ul>
                <h2 className='mt-10 text-lg text-bold text-gray-900'>
                   The advantages you can enjoy by selling to us:
                </h2>
                <ul className="mt-3 max-w-xl space-y-2 text-gray-600">
                    <CheckListItem content='Close on the date of your choice.' />
                    <CheckListItem content='Avoid fix up, repair, or modeling costs.' />
                    <CheckListItem content='Avoid marketing and selling costs.' />
                    <CheckListItem content='Sell quickly and easily for top dollar.' />
                    <CheckListItem content='We buy all types of residential real estate.' />
                    <CheckListItem content='Overcome challenges of selling investment property.' />
                    <CheckListItem content='Convert your entire portfolio into cash or passive income.' />
                    <CheckListItem content='Find a new home before selling.' />
                    <CheckListItem content='Learn what your property is worth' />
                </ul>
                <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">How it works: 7 Steps</h2>
                <p className='mt-5'>
                    1. Decide that you're interested in selling.
                </p>
                <p className='mt-2'>
                    2. Provide us with the property details.
                </p>
                <p className='mt-2'>
                    3. Schedule a time to discuss your preferences, desires, and goals with us.
                </p>
                <p className='mt-2'>
                    4. Give us a few days to do our market research and determine exactly what is the best we can offer you.
                </p>
                <p className='mt-2'>
                    5. Schedule a time to discuss our offer and get any of your questions answered.
                </p>
                <p className='mt-2'>
                    6. Sign agreement and prepare for closing.
                </p>
                <p className='mt-2'>
                    7. Close the sale of your property!
                </p>
                </div>
            </div>
        </div>
    )
}
