    import { HomeIcon, BanknotesIcon } from '@heroicons/react/20/solid'

    const features = [
    {
        name: 'Sell Your Home',
        description:
        'Discover the three options available to you for selling your property and explore the pros and cons of each. Whether you choose to sell with the help of a licensed agent, sell it yourself, or sell it to a group of private investors and contractors, learn about the advantages and benefits of each option and find the best fit for your needs.',
        href: '/sellyourhome',
        icon: HomeIcon,
    },
    {
        name: 'Buy a Home',
        description:
        "Buying a home is a big decision, and there are several important factors to consider before you start your search. On this page, we'll explore key considerations like your desired parameters and loan qualifications, and offer additional programs to help you get on the path to homeownership.",
        href: '#',
        icon: BanknotesIcon,
    },
    ]

export default function OnThisSite() {
    return (
        <div className="bg-sky-50 py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                <h2 className="text-base font-semibold leading-7 text-sky-700">Real Estate Guide</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Your Guide to San Diego Real Estate
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                    Discover everything you need to know about buying and selling properties in San Diego, including how to sell your property "as is" at a fair price, tips for finding your dream home, and real testimonials from satisfied clients.
                </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
                    {features.map((feature) => (
                    <div key={feature.name} className="flex flex-col">
                        <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                        <feature.icon className="h-5 w-5 flex-none text-sky-600" aria-hidden="true" />
                        {feature.name}
                        </dt>
                        <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                        <p className="flex-auto">{feature.description}</p>
                        <p className="mt-6">
                            <a href={feature.href} className="text-sm font-semibold leading-6 text-sky-600">
                            Learn more <span aria-hidden="true">→</span>
                            </a>
                        </p>
                        </dd>
                    </div>
                    ))}
                </dl>
                </div>
            </div>
        </div>
    )
}
