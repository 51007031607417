export default function ContactMeFooter() {
    return (
      <div className="bg-sky-100">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Ready to make your real estate dreams a reality?
            <br />
            Contact me today and let's get started!
          </h2>
          <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
            <a
              href='/contactme'
              className="rounded-full bg-sky-600 px-10 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
            >
              Contact Me
            </a>
          </div>
        </div>
      </div>
    )
  }
  