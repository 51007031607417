import NavBar from '../components/NavBar';
import Hero from '../sections/Hero';
import AboutMe from '../sections/AboutMe';
import OnThisSite from '../sections/OnThisSite';
import ContactMeForm from '../sections/ContactMeFooter';
import TestimonialsSection from '../sections/TestimonialsSection';

export default function HomePage() {
  return (
    <div>
      <NavBar activePage="Home" />
      <Hero />
      <AboutMe />
      <OnThisSite />
      <TestimonialsSection />
      <ContactMeForm />
    </div>
  )
}