import ContactMeSection from "../sections/ContactMeSection";
import NavBar from '../components/NavBar';

export default function ContactMePage() {
    return (
        <div>
            <NavBar activePage="Contact Me" />
            <ContactMeSection />
        </div>
    )
}