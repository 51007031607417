import NavBar from '../components/NavBar'
import ContactMeForm from '../sections/ContactMeFooter'

export default function NoteBuyingPage() {
    return (
        <div>
            <NavBar activePage="Note Buying" />
            <div className="bg-white px-6 py-32 lg:px-8">
                <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                    <div>
                        <h1 className="inline-block mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                            I buy&nbsp;
                            <span className='relative'>
                                trust deed
                                <span className='absolute bottom-0 left-0 w-full h-0.5 bg-black'></span>
                            </span>
                            &nbsp;or&nbsp;
                            <span className='relative'>
                                mortgage note
                                <span className='absolute bottom-0 left-0 w-full h-0.5 bg-black'></span>
                            </span>
                            .
                        </h1>
                        <h2 className='mt-2 text-2xl text-bold tracking tight text-gray-800 sm:text-3xl'>
                            Performing or non-performing, whole or partial. 
                        </h2>
                    </div>
                    <p className="mt-6 text-xl leading-8">
                        Are you currently receiving payments on a mortgage, trust deed or contract for a deed?
                    </p>
                    <div>
                        <p className="inline-block mt-3 text-xl leading-8">
                            I buy&nbsp;
                            <span className='bg-gray-200'>trust deed</span>
                            &nbsp;or&nbsp;
                            <span className='bg-gray-200'>mortgage note</span>
                            .&nbsp;Performing or non-performing, whole or partial. 
                        </p>
                    </div>
                    <p className="mt-6 text-xl leading-8">
                        If you want to cash out your mortgage, trust deed, or contact for deed,&nbsp;
                        <a href='/contactme' className='text-sky-600 hover:text-sky-400 underline'>
                            let's talk
                        </a> 
                        .
                    </p>
                    <p className='mt-5 text-2xl text-bold'>
                        4 quick steps:
                    </p>
                    <p className='mt-2'>
                        1. Inquiry - contact me for a free quote with a cash offer (no obligation).
                        <br />
                        2. Due diligence - provide the necessary document
                        <br />
                        3. Offer - Receive a cash offer within 48 hours. 
                        <br />
                        4. Payout - Accept the offer and receive a lump sum payment
                    </p>
                </div>
            </div>
            <ContactMeForm />
        </div>
    )
}