import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import './App.css';


import HomePage from './pages/HomePage'
import SellYourHomePage from './pages/SellYourHomePage';
import BuyAHomePage from './pages/BuyAHomePage';
import NoteBuyingPage from './pages/NoteBuyingPage';
import ContactMePage from './pages/ContactMePage';
import TestimonialsPage from './pages/TestimonialsPage';
import ThankYouPage from './pages/ThankYouPage';

const GA_TRACKING_ID = 'G-4KN6FYG0WK'

export default function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(GA_TRACKING_ID)
  }, []);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='sellyourhome' element={<SellYourHomePage />} />
        <Route path='buyahome' element={<BuyAHomePage />} />
        <Route path='notebuying' element={<NoteBuyingPage />} />
        <Route path='contactme' element={<ContactMePage />} />
        <Route path='testimonials' element={<TestimonialsPage />} />
        <Route path='thankyou' element={<ThankYouPage />} />
      </Routes>
    </div>
  )
}