export default function BuyAHomeInfo() {
    return (
        <div className="bg-white px-6 py-32 lg:px-8">
            <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Buy a Home
                </h1>
                <p className="mt-6 text-xl leading-8">
                    Before shopping for a home, there are several things to consider:
                </p>
                <p className='mt-5'>
                    1. What you want - parameters
                </p>
                <p className='mt-2'>
                    2. Can you qualify for a loan? How much?
                </p>
                <p className="mt-10 text-xl leading-8">
                   If you can't qualify for a loan at this time, we have other programs to help you.  
                </p>
                <p className='mt-3'>
                    Our brokage specializes on this. Find out more here:
                </p>
                <a
                    href='https://covestproperties.com/'
                    className='ml-5 underline hover:text-sky-500'
                >
                    covestproperties.com
                </a>
                <p className='mt-5 ml-5'>
                    • 1 + 1 = 3
                    <br />
                    • Covest Properties 
                    <br />
                    • (Results Better than the Sum of the Parts)
                </p>
            </div>
        </div>
    )
}